<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="company"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('company.name')" prop="name">
            <el-input v-model="company.name" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.factoryNo1')" prop="factoryNo1">
            <el-input v-model="company.factoryNo1" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.abbreviation')" prop="abbreviation">
            <el-input v-model="company.abbreviation" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.brand')" prop="brand">
            <el-input v-model="company.brand" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.person')" prop="person">
            <el-input v-model="company.person" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.phone')" prop="phone">
            <el-input v-model="company.phone" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.unifiedSocialCreditCode')" prop="manufacturerCode">
            <el-input v-model="company.manufacturerCode" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.externalNo')" prop="externalNo">
            <el-input v-model="company.externalNo" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.parentId')" prop="parentId">
            <el-input v-model="company.parentName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.selectParent.open()"></el-button>
            </el-input>
            <company-select ref="selectParent" @select="onSelectParent"></company-select>
          </el-form-item>
          <div></div>
        </div>
        <div v-if="userName === 'admin'" class="vm-separate">
          <el-form-item :label="$t('company.dedicatedCode')" prop="dedicatedCode">
            <el-input v-model="company.dedicatedCode" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
        <div v-if="userEditAuth">
          <el-divider content-position="left">用户信息</el-divider>
          <div class="vm-separate">
            <el-form-item :label="$t('user.username')" prop="username">
              <el-input
                v-model="company.username"
                :placeholder="$t('common.pleaseEnter')"
                auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.password')" prop="password">
              <el-input
                v-model="company.password"
                type="text"
                :class="isShowPassword ? '' : 'displayPass'"
                :placeholder="$t('common.pleaseEnter')"
                autocomplete="new-password"></el-input>
              <i
                class="el-icon-view"
                :class="{ icon: isShowPassword }"
                style="position: absolute;right: 8px;top: 8px;color: #e6e6e6"
                @click="show"></i>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('user.name')" prop="personName">
              <el-input v-model="company.personName " :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.phone')" prop="contactTel">
              <el-input v-model="company.contactTel" :placeholder="$t('common.pleaseEnter')" :maxlength="20"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('user.type')" prop="userType">
              <el-select v-model="company.userType" style="width: 100%">
                <el-option
                  v-for="item in userTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否维保工" prop="isMaintWorker">
              <el-select v-model="company.isMaintWorker" style="width: 100%">
                <el-option :label="$t('common.yes')" :value="1"></el-option>
                <el-option :label="$t('common.no')" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-divider content-position="left">分配权限</el-divider>
          <div class="vm-separate">
            <el-form-item label="对应角色" prop="roleIds">
              <el-select
                v-model="company.roleIds"
                multiple
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%"
                clearable>
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <div></div>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {companyType, companyType_en, typeToArray} from "@/util/constant";
  import CompanySelect from "@/views/company/CompanySelect";
  import auth from "@/util/auth";

  const moduleName = "companies";

  export default {
    components: {
      CompanySelect,
    },
    data() {
      return {
        userEditAuth: this.$auth(2),
        userName: auth.getUsername(),
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        companyTypes: [],
        userTypes: [],
        roleList: [],
        isShowPassword: false,
        company: {
          id: 0,
          no: "",
          name: "",
          abbreviation: "",
          externalNo: "",
          manufacturerCode: "",
          brand: "",
          type: "",
          parentId: 0,
          parentName: "",
          person: "",
          factoryNo1: "",
          phone: "",
          dedicatedCode: 0,
          username: "",
          password: "",
          personName: "",
          contactTel: "",
          roleIds: [],
          userType: "",
          isMaintWorker: "",
        },
        ruleValidate: {
          name: [
            {required: true, message: this.$t("company.tip.name"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.company.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("company.company");
      },
    },
    mounted() {
      this.companyTypes = typeToArray(this.$t("lang") === "en" ? companyType_en : companyType);
    },
    methods: {
      open(id, type) {
        this.dialogVisible = true;
        this.company.id = id;
        this.company.type = type;
        this.initUserTypes();
        this.getRoles();
        if (id > 0) {
          this.getData();
        }
      },
      show() {
        this.isShowPassword = !this.isShowPassword;
      },
      initUserTypes() {
        let userType = [];
        let types = [];
        if (auth.getUsername() === "admin" || auth.getUsername() === "superAdmin") {
          types = [0, 1, 9];
        } else if (auth.getUserType() === 1) { //企业管理员可以建立企业管理员与企业普通用户
          types = [1, 2];
        } else {//企业普通用户只能建立企业普通用户
          types = [2];
        }
        for (let type of types) {
          let item = {
            value: type,
            label: this.$t("user.types." + type),
          };
          userType.push(item);
        }
        this.userTypes = userType;
      },
      getRoles() {
        if (this.roleList.length === 0) { // 此时需要获取角色列表
          let params = {
            pageSize: 1000,
          };
          this.$api.getList("roles/new", params).then(res => {
            this.roleList = res.data.records;
          });
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.company.id).then(res => {
          this.company = Object.assign(this.company, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectParent(row) {
        this.company.parentId = row.id;
        this.company.parentName = row.name;
      },
      handleSubmit() {
        if (this.company.username && this.company.password) {
          if (this.company.username) {
            let rule = /^[a-zA-Z0-9_@.]{4,25}$/;
            if (!rule.test(this.company.username)) {
              return this.$message.error(this.$l("user.userNameRule", "账号为4~25位字符，只能包含英文字母、数字、@、点、下划线"));
            }
          }
          if (this.company.password) {
            let rule = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/;
            if (!rule.test(this.company.password)) {
              return this.$message.error("密码为8~30位字符，必须包含大写字母、小写字母、数字");
            }
          }
          if (!this.company.personName) {
            this.$message.error("姓名不能为空");
            return;
          }
          if (!this.company.contactTel) {
            this.$message.error("手机号不能为空");
            return;
          } else {
            let rule = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/;
            if (!rule.test(this.company.contactTel)) {
              return this.$message.error("请输入正确的手机号");
            }
          }
          if (this.company.userType === "") {
            this.$message.error("用户类型不能为空");
            return;
          }
          if (this.company.isMaintWorker === "") {
            this.$message.error("是否维保工不能为空");
            return;
          }
          if (this.company.roleIds.length === 0) {
            this.$message.error("对应角色不能为空");
            return;
          }
        }
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.company).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.company.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onDialogClose() {
        this.company.parentName = "";
        this.company.contactTel = "";
        this.$refs.formValidate.resetFields();
      },
    },
  };
</script>

<style lang="scss">
.displayPass {
  //将文本替换为指定的字符
  -webkit-text-security: disc;
}
</style>
