<template>
  <el-dialog
    :title="$l('company.addUser','新增用户')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="company"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('user.username')" prop="username">
            <el-input
              v-model="company.username"
              :placeholder="$t('common.pleaseEnter')"
              auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.password')" prop="password">
            <el-input
              v-model="company.password"
              type="text"
              :class="isShowPassword ? '' : 'displayPass'"
              :placeholder="$t('common.pleaseEnter')"
              autocomplete="new-password"></el-input>
            <i
              class="el-icon-view"
              :class="{ icon: isShowPassword }"
              style="position: absolute;right: 8px;top: 8px;color: #e6e6e6"
              @click="show"></i>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('user.name')" prop="personName">
            <el-input v-model="company.personName " :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.phone')" prop="contactTel">
            <el-input v-model="company.contactTel" :placeholder="$t('common.pleaseEnter')" :maxlength="20"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('user.type')" prop="userType">
            <el-select v-model="company.userType" style="width: 100%">
              <el-option
                v-for="item in userTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否维保工" prop="isMaintWorker">
            <el-select v-model="company.isMaintWorker" style="width: 100%">
              <el-option :label="$t('common.yes')" :value="1"></el-option>
              <el-option :label="$t('common.no')" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="对应角色" prop="roleIds">
            <el-select
              v-model="company.roleIds"
              multiple
              :placeholder="$t('common.pleaseSelect')"
              style="width: 100%"
              clearable>
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <div></div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {companyType, companyType_en, typeToArray} from "@/util/constant";
  import auth from "@/util/auth";

  const moduleName = "companies";

  export default {
    data() {
      return {
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        companyTypes: [],
        userTypes: [],
        roleList: [],
        isShowPassword: false,
        company: {
          id: 0,
          name: "",
          username: "",
          password: "",
          personName: "",
          contactTel: "",
          roleIds: [],
          userType: "",
          isMaintWorker: "",
        },
      };
    },
    computed: {
      ruleValidate() {
        return {
          username: {
            required: true,
            pattern: /^[a-zA-Z0-9_@.]{4,25}$/,
            message: this.$l("user.userNameRule", "4~25位字符，只能包含英文字母、数字、@、点、下划线"),
            trigger: "blur",
          },
          personName: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          userType: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          isMaintWorker: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          roleIds: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          password: {
            required: true,
            pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
            message: this.$l("user.tip.password", "8~30位字符，必须包含大写字母、小写字母、数字"),
            trigger: "blur",
          },
          contactTel: {
            required: true,
            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/,
            message: this.$t("user.tip.phone"),
            trigger: "blur",
          },
        };
      },
    },
    mounted() {
      this.companyTypes = typeToArray(this.$t("lang") === "en" ? companyType_en : companyType);
    },
    methods: {
      open(id, name) {
        this.dialogVisible = true;
        this.company.id = id;
        this.company.name = name;
        this.initUserTypes();
        this.getRoles();
      },
      show() {
        this.isShowPassword = !this.isShowPassword;
      },
      initUserTypes() {
        let userType = [];
        let types = [];
        if (auth.getUsername() === "admin" || auth.getUsername() === "superAdmin") {
          types = [0, 1, 9];
        } else if (auth.getUserType() === 1) { //企业管理员可以建立企业管理员与企业普通用户
          types = [1, 2];
        } else {//企业普通用户只能建立企业普通用户
          types = [2];
        }
        for (let type of types) {
          let item = {
            value: type,
            label: this.$t("user.types." + type),
          };
          userType.push(item);
        }
        this.userTypes = userType;
      },
      getRoles() {
        if (this.roleList.length === 0) { // 此时需要获取角色列表
          let params = {
            pageSize: 1000,
          };
          this.$api.getList("roles/new", params).then(res => {
            this.roleList = res.data.records;
          });
        }
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.post("company/addUser", this.company).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.company.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onDialogClose() {
        this.company.parentName = "";
        this.company.contactTel = "";
        this.$refs.formValidate.resetFields();
      },
    },
  };
</script>

<style lang="scss">
.displayPass {
  //将文本替换为指定的字符
  -webkit-text-security: disc;
}
</style>
